import React from "react";

const Empty = () => {
  return (
    <div>
      <svg viewBox="0 0 100 100"></svg>
    </div>
  );
};

export default Empty;
