const Pieces = {
  BLACK_SPHERE: "s",
  BLACK_TETRAHEDRON: "t",
  BLACK_PYRAMID: "p",
  BLACK_CUBE: "c",
  WHITE_SPHERE: "S",
  WHITE_TETRAHEDRON: "T",
  WHITE_PYRAMID: "P",
  WHITE_CUBE: "C",
};

export default Pieces;
